import { useState } from "react";
import { useForm } from "react-hook-form";
import { Input } from "@components/ui/input";
import { validate } from "email-validator";
import { useAuth, useLogin } from "@lib/api-hooks";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { getLocalStorage } from "@lib/util-functions/getLocalStorage";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { Button } from "@components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
export type LoginFormData = z.infer<typeof loginSchema>;

const loginSchema = z.object({
  email: z
    .string({ required_error: "Email is required" })
    .email({ message: "Please enter a valid email address" }),
  password: z
    .string({ required_error: "Password is required" })
    .min(8, { message: "Password must be at least 8 characters" }),
});

const LoginView = () => {
  const form = useForm<LoginFormData>({ resolver: zodResolver(loginSchema) });
  const { isPending } = useAuth();

  const posthog = usePostHog();

  const loginMutation = useLogin();
  const [errorMessage, setErrorMessage] = useState("");
  const router = useRouter();
  const onSubmit = async ({ email, password }: LoginFormData) => {
    if (loginMutation.isPending || isPending || !email || !password) return;
    try {
      await loginMutation.mutateAsync({ email, password });
      posthog?.capture("User logged in");

      let nextPath = router.query.next?.toString() ?? "";
      if (nextPath.includes("/login")) nextPath = "";
      if (!getLocalStorage()) {
        router.replace(nextPath === "" ? "/" : nextPath);
      } else {
        window.location.href = nextPath === "" ? "/" : nextPath;
      }
    } catch (err: any) {
      console.log(err);
      setErrorMessage(
        `Unable to log into account '${email}': ${err.error ?? err.message}`
      );
    }
  };

  return (
    <div className="w-screen h-full min-h-screen flex flex-col justify-center items-center overflow-y-auto px-4 md:px-0 bg-gray-100 dark:bg-gray-800">
      <div className="max-w-[90%] w-96 md:w-[488px] flex flex-col rounded-3xl bg-gray-200 dark:bg-gray-700 border-2 border-gray-200 dark:border-gray-700 px-8 py-14">
        <div className="flex items-center m-auto justify-center bg-indigo-600 rounded-full w-24 h-24">
          <LockClosedIcon className="text-white dark:text-foreground w-12 h-12" />
        </div>
        <p className="text-foreground text-4xl font-bold my-5 text-center">
          Dashboard Login
        </p>
        <p className="text-gray-200 text-center mb-5">
          Welcome to the Tracify Dashboard. Please enter your Tracify user
          credentials below to access analytics.
        </p>
        {errorMessage && <p className="text-red-500 mb-2">{errorMessage}</p>}
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Username</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="email"
                      variant="alternate"
                      placeholder="Email Address"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="relative">
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        type="password"
                        variant="alternate"
                        placeholder="Password"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <a
                href={`mailto:support@tracify.ai?subject=${encodeURIComponent(
                  "Priority: Help reset Tracify Password"
                )}&body=${encodeURIComponent(
                  "Hi Customer Support,\n\nI forgot my password for Tracify. Can you help me reset it?\n\nThanks"
                )}`}
                className="absolute right-0 top-0 text-sm text-link hover:underline"
              >
                Forgot Password?
              </a>
            </div>
            <div className="mt-4">
              <Button
                variant="primary"
                type="submit"
                className="w-full"
                loading={loginMutation.isPending || isPending}
              >
                Enter Now
              </Button>
            </div>
          </form>
        </Form>
        <p className="text-gray-300 text-center mb-5 mt-5">
          <a
            href="https://www.tracify.ai/pages/imprint"
            target="_blank"
            rel="noreferrer"
          >
            Imprint
          </a>{" "}
          |{" "}
          <a
            href="https://www.tracify.ai/pages/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
};

export default LoginView;
