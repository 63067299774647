import Head from "@components/Commons/Head";
import LoginView from "../components/Login/LoginView";

function LoginPage() {
  return (
    <div className="w-screen min-h-screen h-full py-8 overflow-y-auto bg-gray-100 dark:bg-gray-800 dark:bg-gray-900">
      <Head title="Login – Tracify AI" />
      <LoginView />
    </div>
  );
}

export default LoginPage;
